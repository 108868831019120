<template>
  <div>
    <van-popup class="popclass" v-model="show" closeable>
      <div class="content">
        <div v-html="desc"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getOptionDesc } from "@/request/api";
("@/request/shopapi");
export default {
  name: "Agreetxt",
  components: {},
  data() {
    return {
      show: false,
      desc: "",
      agreement: "merchant_agreement",
    };
  },

  mounted() {},

  methods: {
    preview(destype) {
      this.show = true;
      this.agreement = destype;
      this.requestDesc();
    },
    requestDesc() {
      const params = {
        name: this.agreement,
      };
      getOptionDesc(params).then((res) => {
        this.desc = res.data.value_zh;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 24px;
  background: white;
  width: 700px;
  min-height: 800px;
  color: black;
}
</style>
