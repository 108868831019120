<template>
  <div class="bgmall">
    <div class="maincontent">
      <div class="hometop">
        <div class="topcontent">
          <div class="tooldiv">
            <div class="leftview">
              <!--<div class="circle"></div>-->
              <!--<div class="logotxt">logo</div>-->
            </div>
            <div class="rightview">
              <img class="icon space" :src="require('@/assets/images/mall/home/searchicon.png')" @click="goAllgoods" />
              <img class="icon" @click="showHelp" :src="require('@/assets/images/mall/home/helpicon.png')" />
            </div>
          </div>
          <div class="infodiv">
            <div class="header">
              <div class="userimg">
                <img class="userhead" :src="require('@/assets/images/logo.png')" style="width: 100%" />
              </div>
              <div class="userinfo">
                <div class="username">
                  <div class="nametxt">{{ userinfo.name }}</div>
                  <img class="levelicon" :src="require('@/assets/images/mall/home/levelicon.png')" />
                </div>
                <div class="idtxt">ID:{{ userinfo.id }}</div>
              </div>
            </div>
            <div class="linkbox">
              <template v-if="!address">
                <div class="txt" @click="connectUser()">钱包链接 ></div>
              </template>
              <template v-else>
                <div class="txt">{{ short_address }}</div>
              </template>
            </div>
          </div>
          <div class="whitebox">
           <!-- <div class="coincell" @click="toWallet">
              <div class="attcoin" v-for="(coin, index) in walletInfo" :key="index" v-if="coin.coin_symbol!='USDT'">
                <div class="cointxt">{{ coin.coin_symbol }}</div>
                <div class="coinnum">
                  {{ parseFloat(coin.cloud_balance).toFixed(2) }}
                </div>
              </div>
            </div>-->
            <div class="myinfo">
              <div class="myasset assetbg" @click="toWallet">
                <div class="leftasset">
                  <div class="title">
                    <div class="txt">我的资产</div>
                    <div class="msgnum">{{ wallet_count }}</div>
                  </div>
                  <div class="describle assettxt">数字资产一清二楚</div>
                </div>
                <img class="asseticon" :src="require('@/assets/images/mall/home/asseticon.png')" />
              </div>
              <div class="myasset orderbg" @click="goOrderList">
                <div class="leftasset">
                  <div class="title">
                    <div class="txt">我的订单</div>
                    <div class="msgnum">{{ order_count }}</div>
                  </div>
                  <div class="describle ordertxt">订单状态一目了然</div>
                </div>
                <img class="asseticon" :src="require('@/assets/images/mall/home/ordericon.png')" />
              </div>
            </div>
          </div>
        </div>
        <div class="menulist">
          <div class="menubox" @click="goAllgoods">
            <img class="menuicon" :src="require('@/assets/images/mall/home/goods.png')" />
            <div class="menutxt">全部商品</div>
          </div>
          <div class="menubox" @click="clickApplyshop">
            <img class="menuicon" :src="require('@/assets/images/mall/home/applyshop.png')" />
            <div class="menutxt">
              <template v-if="storeinfo.store_id">商家管理</template>
              <template v-else>申请商家</template>
            </div>
          </div>
          <div class="menubox" @click="clickUsercenter()">
            <img class="menuicon" :src="require('@/assets/images/mall/home/attcon.png')" />
            <div class="menutxt">生态环保</div>
          </div>
          <div class="menubox" @click="toOrder">
            <img class="menuicon" :src="require('@/assets/images/mall/home/usercenter.png')" />
            <div class="menutxt">消费全返</div>
          </div>
        </div>

       <!-- <div class="swiper">
          <van-swipe class="myswipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item in bannerList" :key="item.id" @click="toBannerUrl(item.url)">
              <img class="bannerimg" :src="item.images_url" />
            </van-swipe-item>
          </van-swipe>
        </div>-->
        <h2 style="padding: 0 0.32rem 0 0.32rem;color: rgb(39, 204, 127);">生活服务</h2>
        <div class="mallHead">
          <div class="itemlist1">
            <div class="item" @click="toBannerUrl(item.url)"  v-for="(item, index) in bannerList" :key="index">
              <img class="itemimg" :src="item.images_url" />
              <div class="itemtitle van-multi-ellipsis--l2">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <h2 style="padding: 0 0.32rem 0 0.32rem;color: rgb(39, 204, 127);">热销专区</h2>
        <div class="itemlist">
          <div v-for="(item, index) in recommendlist" :key="index">
            <div class="item" @click="goGoodsDetail(item)">
              <img class="itemimg" :src="item.images" />
              <div class="itemtitle van-multi-ellipsis--l2">
                {{ item.name }}
              </div>
              <div class="bottom">
                <div class="price">${{ parseFloat(item.price).toFixed(2) }}≈￥{{ parseFloat(item.price*cny_to_us).toFixed(2) }}</div>
                <div class="todetail">查看 ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <reg-sheet ref="regsheet"></reg-sheet>
    <agreetxt ref="agreetxt"></agreetxt>
  </div>
</template>

<script>
import { getStatistics, getRecommendList, getStoreInfo } from "@/request/shopapi";
import Agreetxt from "./actionsheet/agreetxt.vue";
import { getUserInfo, getWalletInfo,getBanner,getOption } from "@/request/api";
import metamaskConfig from "../../components/connection.js";
import { ethers } from "../../components/ethers-5.1.esm.min.js";
import RegSheet from "../index/modal/regSheet.vue";
let defaultAccount, networkVersion;
import { Toast } from "vant";

export default {
  components: {
    RegSheet,
    Agreetxt,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      order_count: 0,
      wallet_count: 0,
      recommendlist: [],
      storeinfo: {
        store_id: 0,
      },
      userinfo: {
        name: "",
        id: "",
      },
      walletInfo: [],
      address: "",
      short_address: "",
        bannerList:[],
        cny_to_us:0,
    };
  },
  mounted() {},
  activated() {
    this.requestRecommendList();
    // this.address = this.$gaddress;
    // sessionStorage.setItem("address", this.address);
    // this.requestData();
    this.checkLogin();
      getBanner({ type: "2" }).then((res) => {
          this.bannerList = res.data;
      });
      getOption({name: 'cny_to_us'}).then(res=> {
          this.cny_to_us = res.data
      })
  },
  methods: {
      toMall(){
          this.$router.push({
              path: "/mall/mallgood",
              query: {

              },
          });
      },
      toOrder(){
          this.$router.push({
              path: "/mall/mallorder",
              query: {

              },
          });
      },
      toBannerUrl(url){
        window.open(url,'_blank')
      },
    async connectUser() {
      let banner = 0;
      let getAccountAddress = await metamaskConfig.getAccount();
      if (getAccountAddress.length < 1) {
        getAccountAddress = await metamaskConfig.connectToAccount();
        banner = await metamaskConfig.getBalance();
        location.reload();
      } else {
        banner = await metamaskConfig.getBalance();
        location.reload();
      }
    },
    async checkLogin() {
      try {
        if (metamaskConfig.isMetamaskConnected) {
          ethereum.autoRefreshOnNetworkChange = false;
          networkVersion = await metamaskConfig.getNetworkId();
          if (networkVersion == 1) {
            //以太坊主网
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 42) {
            //以太坊测试网  K
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 128) {
            //HECO主网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 256) {
            //HECO测试网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 56) {
            //币安主网
            console.log("BNB");
          } else {
            console.log("其他");
            Toast("请使用币安智能链链接");
            return false;
          }
          defaultAccount = await metamaskConfig.connectToAccount();
          console.log("address connected:", defaultAccount);
          if (defaultAccount[0]) {
            this.address = defaultAccount[0];
            this.short_address = this.address.substr(0, 3) + "..." + this.address.substr(this.address.length - 3);
            sessionStorage.removeItem("address");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("level_id");
            sessionStorage.setItem("address", this.address);
            if (this.address && sessionStorage.getItem(this.address)) {
              this.requestData();
              // this.requestStatistics();
              // this.requestStoreInfo();
              // this.requestgetUserInfo();
              // this.getWalletInfoData();
            }
          }
        } else {
          Toast("请使用币安智能链链接");
          console.log("Connect to available ethereum network!");
        }
      } catch (e) {
        this.$refs.regsheet.preview();
        console.log("1111");
      }
    },
    toWallet() {
      this.$router.push({
        path: "/home/mine/transactions",
        query: {},
      });
    },
    toIndex() {
      this.$router.push({
        path: "/home",
        query: {},
      });
    },
    getWalletInfoData() {
      const params = {
        address: this.address,
      };
      getWalletInfo(params).then((res) => {
        this.walletInfo = res.data.list;
      });
    },
    showHelp() {
      this.$refs.agreetxt.preview("mell_desc");
    },
    requestData() {
      this.requestStatistics();
      this.requestStoreInfo();
      this.requestgetUserInfo();
      this.getWalletInfoData();
    },
    requestgetUserInfo() {
      getUserInfo({ address: this.address }).then((res) => {
        this.userinfo = res.data;
        sessionStorage.setItem("user", JSON.stringify(res.data));
        sessionStorage.setItem("level_id", res.data.level_id);
      });
    },
    goGoodsDetail(item) {
      this.$router.push({
        path: "/mall/goodsdetail",
        query: {
          id: item.id,
        },
      });
    },
    goOrderList() {
      this.$router.push({
        path: "/mall/myorderlist",
      });
    },
    goAllgoods() {
      this.$router.push({
        path: "/mall/goodslist",
      });
    },
    clickApplyshop() {
      // Toast("暂未开放");
      // return false;
      if (this.storeinfo.store_id) {
          if(this.storeinfo.status==1){
              this.$router.push({
                  path: "/mall/malllogin",
              });
          }else if(this.storeinfo.status==0){
              Toast("店铺申请审核中，请等待");
          }else{
              this.$router.push({
                  path: "/mall/applyshop",
              });
          }
      } else {
        this.$router.push({
          path: "/mall/applyshop",
        });
      }
    },
    clickUsercenter() {
      this.$router.push({
        path: "/home/mine",
      });
    },
    requestRecommendList() {
      getRecommendList().then((res) => {
        this.recommendlist = res.data;
      });
    },
    requestStatistics() {
      getStatistics({ address: this.address }).then((res) => {
        console.log(res);
        this.order_count = res.data.order_count;
        this.wallet_count = res.data.wallet_count;
      });
    },
    requestStoreInfo() {
      getStoreInfo({ address: this.address }).then((res) => {
        if (res.data) {
          this.storeinfo = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bgmall {
  background: #ebeef2;
  min-height: 100vh;
  padding-bottom: 10px;
  .maincontent {
    .hometop {
      padding-top: 88px;
      background: url("../../assets/images/mall/home/topbg.png");
      background-size: 100% 100%;
      height: 600px;
      .topcontent {
        padding: 24px 24px 0px;
        .tooldiv {
          display: flex;
          justify-content: space-between;
          .leftview {
            display: flex;
            align-items: center;
            .circle {
              width: 48px;
              height: 48px;
              border-radius: 88px;
              opacity: 1;
              background: rgba(255, 114, 32, 1);
              margin-right: 8px;
            }
            .logotxt {
              color: rgba(41, 33, 29, 1);
              font-size: 24px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: center;
              line-height: 36px;
            }
          }
          .rightview {
            display: flex;
            .space {
              margin-right: 24px;
            }
            .icon {
              width: 64px;
              height: 64px;
            }
          }
        }
        .infodiv {
          margin-top: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .header {
            display: flex;
            .userimg {
              width: 80px;
              height: 80px;
              opacity: 1;
              /*border: 2px solid rgba(255, 114, 32, 1);*/
              margin-right: 4px;
              border-radius: 40px;
            }
            .userinfo {
              .username {
                display: flex;
                align-items: center;
                .nametxt {
                  color: rgba(41, 33, 29, 1);
                  font-size: 28px;
                  font-weight: 700;
                  font-family: "PingFang SC";
                  text-align: left;
                  line-height: 40px;
                }
                .levelicon {
                  width: 140px;
                  height: 44px;
                }
              }
            }
            .idtxt {
              margin-top: 10px;
              color: rgba(156, 152, 150, 1);
              font-size: 20px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: left;
              line-height: 32px;
            }
          }
          .linkbox {
            width: 176px;
            height: 52px;
            border-radius: 40px;
            opacity: 1;
            background: rgba(255, 247, 242, 1);
            color: rgba(255, 114, 32, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 52px;
            text-align: center;
          }
        }
        .whitebox {
          margin-top: 22px;
          padding: 24px;
          width: 702px;
          border-radius: 24px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          .coincell {
            display: flex;
            justify-content: space-between;
            .attcoin {
              width: 200px;
              height: 104px;
              border-radius: 8px;
              opacity: 1;
              border: 2px solid rgba(255, 223, 204, 1);
              background: rgba(255, 247, 242, 1);
              padding: 16px;
              .cointxt {
                color: rgba(156, 152, 150, 1);
                font-size: 24px;
                font-weight: 700;
                font-family: "PingFang SC";
                line-height: 36px;
              }
              .coinnum {
                color: rgba(90, 42, 14, 1);
                font-size: 28px;
                font-weight: 500;
                font-family: "DIN";
                text-align: left;
                line-height: 36px;
              }
            }
          }
          .myinfo {
            /*margin-top: 24px;*/
            display: flex;
            justify-content: space-between;
            .assetbg {
              background: linear-gradient(180deg, rgba(255, 235, 215, 1) 0%, rgba(255, 240, 205, 1) 100%);
            }
            .orderbg {
              background: linear-gradient(180deg, rgba(255, 215, 215, 1) 0%, rgba(255, 218, 205, 1) 100%);
            }
            .ordertxt {
              color: rgba(153, 36, 36, 1);
            }
            .assettxt {
              color: rgba(153, 118, 36, 1);
            }
            .myasset {
              width: 314px;
              height: 200px;
              border-radius: 8px;
              opacity: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .leftasset {
                padding: 0 0 0 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .title {
                  display: flex;
                  align-items: center;
                  .txt {
                    color: rgba(77, 58, 16, 1);
                    font-size: 24px;
                    font-weight: 700;
                    font-family: "PingFang SC";
                    text-align: left;
                    line-height: 36px;
                    margin-right: 8px;
                  }
                  .msgnum {
                    width: 40px;
                    height: 30px;
                    border-radius: 16px;
                    opacity: 1;
                    background: rgba(77, 58, 16, 1);
                    color: rgba(255, 255, 255, 1);
                    font-size: 20px;
                    text-align: center;
                    line-height: 30px;
                  }
                }
                .describle {
                  margin-top: 5px;
                  font-size: 20px;
                  font-weight: 400;
                  font-family: "PingFang SC";
                  text-align: left;
                  line-height: 32px;
                }
              }
              .asseticon {
                width: 96px;
                height: 96px;
              }
            }
          }
        }
      }
      .menulist {
        margin-top: 38px;
        padding: 0 24px;
        display: flex;
        justify-content: space-around;
        .menubox {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .menuicon {
            width: 80px;
            height: 80px;
            border-radius: 24px;
          }
          .menutxt {
            color: rgba(41, 33, 29, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            margin-top: 8px;
          }
        }
      }
      .swiper {
        padding: 32px 32px;
        display: flex;
        justify-content: center;
        .myswipe {
          .bannerimg {
            width: 686px;
            height: 686px;
          }
        }
      }
      .itemlist {
        background: #ebeef2;
        margin-top: 14px;
        padding: 0 24px 16px 24px;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        .item {
          margin-bottom: 24px;
          width: 340px;
          height: 500px;
          border-radius: 24px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          .itemimg {
            width: 340px;
            height: 340px;
            border-radius: 24px 24px 0 0;
            opacity: 1;
            background: rgba(255, 114, 32, 1);
            object-fit: cover;
          }
          .itemtitle {
            margin-top: 16px;
            padding: 0 16px;
            color: rgba(41, 33, 29, 1);
            font-size: 30px;
            font-weight: 600;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 36px;
          }
          .bottom {
            margin-top: 16px;
            padding: 0 16px;
            display: flex;
            justify-content: space-between;
            .price {
              color: rgba(255, 114, 32, 1);
              font-weight: 500;
              font-family: "DIN";
              text-align: left;
              line-height: 40px;
              font-size: 28px;
            }
            .todetail {
              color: rgba(156, 152, 150, 1);
              font-size: 24px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: left;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}
.mallHead{
  .itemlist1 {
    background: #ebeef2;
    margin-top: 14px;
    padding: 0 24px 16px 24px;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    .item {
      margin-bottom: 24px;
      width: 210px;
      height: auto;
      border-radius: 24px;
      opacity: 1;
      /*background: rgba(255, 255, 255, 1);*/
      .itemimg {
        width: 150px;
        height: 150px;
        margin: 0 30px;
        border-radius: 24px 24px 0 0;
        opacity: 1;
        /*background: #fff;*/
        /*object-fit: cover;*/
      }
      .itemtitle {
        /*margin-top: 16px;*/
        padding: 0 16px;
        color: rgba(41, 33, 29, 1);
        font-size: 30px;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 36px;
      }
      .bottom {
        margin-top: 16px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        .price {
          color: rgba(255, 114, 32, 1);
          font-weight: 500;
          font-family: "DIN";
          text-align: left;
          line-height: 40px;
          font-size: 28px;
        }
        .todetail {
          color: rgba(156, 152, 150, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 32px;
        }
      }
    }
  }
}

</style>
